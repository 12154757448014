export const sendTelegramMessage = async (chatId, message) => {
    const url = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_API_KEY}/sendMessage`;

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            chat_id: chatId,
            text: message,
            parse_mode: "HTML",
        }),
        });

        if (!response.ok) {
        throw new Error(`Telegram API error: ${response.statusText}`);
        }

        console.log('Message sent successfully!');
    } catch (error) {
        console.error('Failed to send Telegram message:', error);
    }
};